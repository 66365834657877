@import "../../assets/scss/variable.scss";

.page-header {
    position: relative;
    padding: 110px 0;
    background: #f5f5f5;
}
.page-header .page-header-wrapper {
    position: relative;
    z-index: 1;
}
.page-header .page-header-inner {
    display: flex;
    flex-flow: column;
    text-align: center;
    margin: 0 auto;
}

.page-header .page-header-title {
    color: $white-color;
    margin: 0;
    font-size: 58px;
    line-height: 70px;
    font-weight: 600;
    margin-bottom: 15px;
}
.page-header .page-header-inner p {
    color: $white-color;
    font-size: 18px;
    margin-bottom: 0;
}
.page-header .page-header-inner p + .page-breadcrumbs {
    margin-top: 20px;
}
.page-header .page-breadcrumbs {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.page-header .page-breadcrumbs li,
.page-header .page-breadcrumbs li a {
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    color: $white-color;
    transition: $transition-3;
}
.page-header .page-breadcrumbs li a i {
    font-size: 12px;
    margin-right: 5px;
    position: relative;
    top: -1px;
}
.page-header .page-breadcrumbs li span,
.page-header .page-breadcrumbs .breadcrumb-item a:hover,
.page-header .page-breadcrumbs .breadcrumb-item.active {
    color: $primary-color;
}
.page-header .page-breadcrumbs li:before {
    content: "/";
    font-size: 16px;
    padding: 0 10px;
    position: relative;
    top: 2px;
    opacity: 0.5;
}
.page-header .page-breadcrumbs li:first-child:before {
    content: normal;
}

/*---------------------------------------*/
/* Overlay */
/*---------------------------------------*/
.page-header .page-header-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba($secondary-color, 0.5);
}
.page-header.bg-hide .page-header-overlay {
    display: none;
}

/*---------------------------------------*/
/* Theme Light */
/*---------------------------------------*/
.page-header.theme-light {
    background-color: $white-color;
}
.page-header.theme-light .page-header-title {
    color: $title-color;
}
.page-header.theme-light .page-header-inner p {
    color: $dark-color;
}
.page-header.theme-light .page-breadcrumbs li,
.page-header.theme-light .page-breadcrumbs li a {
    color: $body-color;
}

/*---------------------------------------*/
/* Theme Dark */
/*---------------------------------------*/
.page-header.theme-dark {
    background-color: $secondary-color;
}
.page-header.theme-dark .page-header-title {
    color: $white-color;
}
.page-header.theme-dark .page-header-inner p {
    color: $white-color;
}
.page-header.theme-dark .page-breadcrumbs li,
.page-header.theme-dark .page-breadcrumbs li a {
    color: $white-color;
}

/*---------------------------------------*/
/* Theme Primary */
/*---------------------------------------*/
.page-header.theme-primary {
    background-color: $primary-color;
}
.page-header.theme-primary .page-header-title {
    color: $white-color;
}
.page-header.theme-primary .page-header-inner p {
    color: $white-color;
}
.page-header.theme-primary .page-breadcrumbs li,
.page-header.theme-primary .page-breadcrumbs li a {
    color: $white-color;
}
.page-header.theme-primary .page-breadcrumbs li span,
.page-header.theme-primary .page-breadcrumbs .breadcrumb-item a:hover,
.page-header.theme-primary .page-breadcrumbs .breadcrumb-item.active {
    color: $white-color;
}

/*==========================================================*/
/* Responsive CSS */
/*==========================================================*/
@media only screen and (max-width: 991px) {
    
    .page-header {padding: 80px 0;}
    .page-header .page-header-title {font-size: 48px; line-height: 58px;}
}

@media only screen and (max-width: 767px) {
    
    .page-header {padding: 50px 0;}
    .page-header .page-header-title {font-size: 36px; line-height: 44px;}
}

@media only screen and (max-width: 575px) {
    
    .page-header .page-header-title {font-size: 30px; line-height: 36px;}
}