@import "../../assets/scss/variable.scss";

.pgs-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.pgs-list li {
	margin-bottom: 15px;
	line-height: 20px;
	color: $dark-color;
}
.pgs-list li i {
	color: $primary-color;
	padding-right: 8px;
}
.pgs-list li a {
	color: $dark-color;
}
.pgs-list li a:hover {
	color: $primary-color;
}